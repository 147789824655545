import React, { useEffect, useState } from 'react';

import { Fab, Typography } from '@material-ui/core';
import { DoubleArrow as DoubleArrowIcon } from '@material-ui/icons';

import Layout from '../../Layout';
import StepNavigator, { STEP } from './StepNavigator';
import ReelItemCard from './ReelItemCard';

import { Category } from '../../../interfaces/category';

import categoryAPI from '../../../api/category';

import './SelectCategoryStep.scss';

const SelectCategoryStep: React.FC = () => {
    const [categories, setCategories] = useState<Array<Category>>([]);
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);

    useEffect(() => {
        async function fetchCategories() {
            setCategories(await categoryAPI.listing(true));
        }

        // noinspection JSIgnoredPromiseFromCall
        fetchCategories();
    }, []);

    return (
        <Layout className="select-category-page" title="Create Your REEL">
            <StepNavigator currentStep={STEP.CATEGORY} />

            <div className="section description-section">
                <Typography variant="body1" color="textPrimary">
                    Welcome to STAR Online Learning System REEL. With REEL, teachers tailor interactive
                    presentations and activities to meet the specific needs of each student.
                </Typography>
                <Typography variant="body1" color="textPrimary">
                    Select a category below to begin developing a presentation based on your student’s
                    strand and skill level.
                </Typography>
            </div>

            <div className="section category-select-section">
                {categories.map((category) => (
                    <div key={category.id} className="category-grid-item">
                        <ReelItemCard
                            item={category}
                            active={(selectedCategory !== null) && (selectedCategory.id === category.id)}
                            onClick={() => {
                                if (selectedCategory && selectedCategory.id === category.id) {
                                    setSelectedCategory(null);

                                    return;
                                }

                                setSelectedCategory(category);
                            }}
                        />
                    </div>
                ))}
            </div>

            <Fab
                variant="extended"
                color="primary"
                aria-label="add"
                className="floating-fab"
                disabled={!selectedCategory}
                href={`/reel/create/${selectedCategory ? selectedCategory.id : ''}`}
            >
                Next
                <DoubleArrowIcon />
            </Fab>
        </Layout>
    );
};

export default SelectCategoryStep;
