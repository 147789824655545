import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { Fab, Typography } from '@material-ui/core';
import { DoubleArrow as DoubleArrowIcon } from '@material-ui/icons';

import Layout from '../../Layout';
import StepNavigator, { STEP } from './StepNavigator';
import ReelItemsRadioGroup from './ReelItemsRadioGroup';

import themeAPI from '../../../api/theme';
import strandAPI from '../../../api/strand';
import categoryAPI from '../../../api/category';

import { emptyCategory, emptyTheme } from '../../../interfaces/defaults';
import { Strand } from '../../../interfaces/strand';
import { Theme } from '../../../interfaces/theme';
import { Category } from '../../../interfaces/category';

import './SelectStrandStep.scss';

const SelectStrandStep: React.FC = () => {
    const [category, setCategory] = useState<Category>({ ...emptyCategory });
    const [theme, setTheme] = useState<Theme>({ ...emptyTheme });
    const [strands, setStrands] = useState<Array<Strand>>([]);
    const [selectedStrand, setSelectedStrand] = useState<Strand | null>(null);

    const { categoryId, themeId } = useParams();

    const history = useHistory();

    useEffect(() => {
        (async () => setCategory(await categoryAPI.single(categoryId)))();
        (async () => setTheme(await themeAPI.single(themeId)))();
        (async () => setStrands(await strandAPI.listing(themeId)))();
    }, [categoryId, themeId]);

    if (strands.length === 1) {
        history.push(`/reel/create/${categoryId}/${themeId}/${strands[0].id}`);
    }

    if (strands.length <= 1) {
        return (<></>);
    }

    return (
        <Layout className="select-strand-page" title="Create Your REEL">
            <StepNavigator currentStep={STEP.STRAND} category={category} theme={theme} />

            <div className="section description-section">
                <Typography variant="body2" color="textPrimary">
                    <p>
                        Select a strand below based on your student’s current developmental level and needs, click on
                        the accordion for more detail on each strand.
                    </p>
                    <p>
                        For further information on how to select a strand consult the Strand Selection Guide found at
                        {' '}
                        <a href="https://www.starautismsupport.com/" target="_blank" rel="nofollow noopener noreferrer">
                            www.starautismsupport.com
                        </a>.
                    </p>
                </Typography>
            </div>

            <div className="section strand-select-section">
                {strands && (
                    <ReelItemsRadioGroup
                        items={strands}
                        onSelectItem={(strand) => setSelectedStrand(strand)}
                    />
                )}
            </div>

            <Fab
                variant="extended"
                color="primary"
                aria-label="add"
                className="floating-fab"
                disabled={!selectedStrand}
                href={`/reel/create/${categoryId}/${themeId}/${selectedStrand ? selectedStrand.id : ''}`}
            >
                Next
                <DoubleArrowIcon />
            </Fab>
        </Layout>
    );
};

export default SelectStrandStep;
