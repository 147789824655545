import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#9FCB3B',
            contrastText: '#FFF',
        },
        secondary: {
            main: '#346FA5',
            contrastText: '#FFF',
        },
        text: {
            primary: '#346FA5',
            secondary: '#FFF',
        },
        background: {
            default: '#F0F4F7',
        },
    },

    typography: {
        h1: {
            fontWeight: 600,
            fontSize: '2rem',
        },
        h2: {
            fontWeight: 500,
            fontSize: '1.75rem',
        },
        h3: {
            fontWeight: 500,
            fontSize: '1.5rem',
        },
        h4: {
            fontWeight: 500,
            fontSize: '1.35rem',
        },
        h5: {
            fontSize: '1.25rem',
        },
        h6: {
            fontSize: '1rem',
        },
    },
});

export default theme;
