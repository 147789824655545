import React from 'react';

import Typography from '@material-ui/core/Typography';

import './Header.scss';
import Logo from './Logo';

interface Props {
    title?: string,
}

const Header: React.FC<Props> = ({ title }) => (
    <div className="header">
        <div className="header-item logo">
            <Logo />
        </div>
        <div className="header-item title">
            <Typography variant="h1">{title}</Typography>
        </div>
        <div className="header-item user-tools">
            <Typography component="span">WELCOME, USER</Typography>
        </div>
    </div>
);

export default Header;
