import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import update from 'immutability-helper';
import { useDrop } from 'react-dnd';

import Fab from '@material-ui/core/Fab';

import {
  BlockContent,
  LessonContent,
  ReelSelection,
} from '../../../interfaces/reel';
import { StrandSets } from '../../../interfaces/strandSets';

import reelAPI from '../../../api/reel';
import setContentAPI from '../../../api/setContent';

import BlockPreview from './BlockPreview';
import { DraggableItemTypes } from './DraggableItemTypes';

interface DisabledLessonState {
  [id: string]: LessonContent;
}

interface Props {
  strandSets: StrandSets;
  editing: boolean;
  onCancel: () => void;
  onUpdateSelection: (reelSelection: ReelSelection) => void;
  reelSelection?: ReelSelection,
}

const getReelSelection = (blocks: BlockContent[], disabledLessons: DisabledLessonState): ReelSelection => ({
  setContents: blocks.filter((block) => block.setContentId).map((block) => block.setContentId),
  skippedLessons: Object.keys(disabledLessons),
});

const ReelContentSection: React.FC<Props> = ({
  strandSets,
  editing,
  onCancel,
  onUpdateSelection,
  reelSelection,
}) => {
  const [blocks, setBlocks] = useState<BlockContent[]>([]);
  const [editingBlocks, setEditingBlocks] = useState<BlockContent[]>([]);

  const disabledLessons = useRef<{ saved: DisabledLessonState, editing: DisabledLessonState }>({
    saved: {},
    editing: {},
  });

  const [, drop] = useDrop({ accept: DraggableItemTypes.BLOCK });

  const handleToggleLessonActive = (lesson: LessonContent) => {
    lesson.active = !lesson.active;

    if (lesson.active) {
      delete disabledLessons.current.editing[lesson.id];
    } else {
      disabledLessons.current.editing[lesson.id] = lesson;
    }

    setEditingBlocks([...editingBlocks]);
  };

  const handleChangeSetContent = async (setContentId: string, block: BlockContent) => {
    if (setContentId === 'hide') {
      block.setContentId = '';
      block.lessons = [];
    } else {
      const lessons = await setContentAPI.lessons(setContentId);
      lessons.forEach((lesson) => lesson.active = !disabledLessons.current.editing[lesson.id]);

      block.lessons = lessons;
      block.setContentId = setContentId;
    }

    setEditingBlocks([...editingBlocks]);
  };

  const findBlock = (id: string) => {
    const block = editingBlocks.filter((b) => b.id === id)[0];
    return {
      block,
      index: editingBlocks.indexOf(block),
    };
  };

  const moveBlock = (id: string, atIndex: number) => {
    const { block, index } = findBlock(id);
    const newBlocks = update(editingBlocks, {
      $splice: [
        [index, 1],
        [atIndex, 0, block],
      ],
    });
    newBlocks.forEach((block, index) => block.sortOrder = index);

    setEditingBlocks(newBlocks);
  };

  useEffect(() => {
    if (!reelSelection || blocks.length > 0) return;

    (async () => {
      const content = await reelAPI.previewContent({
        setContents: reelSelection.setContents,
        skippedLessons: [],
      });

      const skippedLessons = reelSelection.skippedLessons;
      content.blocks.forEach((block) =>
        block.lessons.forEach((lesson) => {
          lesson.active = !skippedLessons.includes(lesson.id);
          if (!lesson.active) {
            disabledLessons.current.saved[lesson.id] = lesson;
          }
        })
      );

      setBlocks([...content.blocks]);
      setEditingBlocks([...content.blocks]);
    })();
  }, [blocks.length, reelSelection]);

  return (
    <div ref={drop} className="section reel-preview-section">
      {(editing ? editingBlocks : blocks).map((block) => (
        <BlockPreview
          key={block.id}
          editing={editing}
          block={block}
          strandSets={strandSets}
          onToggleLessonActive={handleToggleLessonActive}
          onChangeSetContent={(setContentId) => handleChangeSetContent(setContentId, block)}
          findBlock={findBlock}
          moveBlock={moveBlock}
        />
      ))}

      {editing && (
        <div className="edit-buttons">
          <Fab
            variant="extended"
            color="primary"
            aria-label="add"
            className="floating-fab save-fab"
            onClick={() => {
              const updatedBlocks = [...editingBlocks];
              disabledLessons.current.saved = { ...disabledLessons.current.editing };
              setBlocks(updatedBlocks);

              onUpdateSelection(getReelSelection(updatedBlocks, disabledLessons.current.saved));
            }}
          >
            Preview
          </Fab>
          <Fab
            variant="extended"
            color="secondary"
            aria-label="add"
            className="floating-fab cancel-fab"
            onClick={() => {
              disabledLessons.current.editing = { ...disabledLessons.current.saved };
              setEditingBlocks([...blocks]);
              onCancel();
            }}
          >
            Cancel
          </Fab>
        </div>
      )}
    </div>
  );
};

export default ReelContentSection;
