import { Set } from '../interfaces/set';

import SolsApiCall from './index';

type SetAPI = {
    listing: (themeId: string, strandId: string) => Promise<Set[]>;
    single: (id: string) => Promise<Set>;
};

const setAPI: SetAPI = {
    async listing(themeId, strandId): Promise<Set[]> {
        const response = await SolsApiCall(`/sets?theme=${themeId}&strand=${strandId}`);

        return response.json();
    },

    async single(id): Promise<Set> {
        const response = await SolsApiCall(`/sets/${id}`);

        return response.json();
    },
}

export default setAPI;
