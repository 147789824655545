import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import { useParams } from 'react-router-dom';

import Layout from '../Layout';
import StudentSlide from './StudentSlide';

import JoinSessionOverlay from './JoinSessionOverlay';

import { Player } from '../../interfaces/player';
import { SlideType } from '../../interfaces/slideType';

import { emptyPlayer, emptySlide } from '../../interfaces/defaults';

import playerAPI from '../../api/player';
import teacherSessionAPI from '../../api/teacherSession';

import './index.scss';

const Student: React.FC = () => {
    const [joined, setJoined] = useState<boolean>(false);
    const [currentSlide, setCurrentSlide] = useState<SlideType>(emptySlide);
    const [player, setPlayer] = useState<Player>({ ...emptyPlayer });
    const [externalDestination, setExternalDestination] = useState('');
    const [playing, setPlaying] = useState<boolean>(false);
    const [volume, setVolume] = useState<number>(0);
    const [seekedTime, setSeekedTime] = useState<number>(0);

    const { sessionId, studentName } = useParams();

    const containerRef = useRef<HTMLDivElement>(null);

    const overlayClickHandler = () => setJoined(true);

    const handleStudentClick = async (coords: any) => {
        await teacherSessionAPI.sendStudentAction(
          sessionId,
          studentName,
          {
              slide: currentSlide.id,
              action: 'click',
              left: Math.floor(coords.x * 1000.0),
              top: Math.floor(coords.y * 1000.0),
          }
        );
    };

    useEffect(() => {
        const setNavigationData = (navigationData: any) => {
            const videoState = navigationData.videoSlideState;
            if (videoState) {
                setPlaying(videoState.playing);
                setVolume(videoState.volume);
                setSeekedTime(videoState.seekedTime);
            } else {
                setPlaying(false);
                setVolume(0.0);
                setSeekedTime(0.0);
            }

            setCurrentSlide(navigationData.currentSlide);

            if (navigationData.externalDestination) {
                setExternalDestination(navigationData.externalDestination);
            } else {
                setExternalDestination('');
            }
        };

        const onMessage = (evt: any) => {
            const data = JSON.parse(evt.data);
            const { navigationData } = data;

            if (navigationData) {
                setNavigationData(navigationData);
            }
        };

        (async () => {
            const response = await teacherSessionAPI.single(sessionId);

            const navigationData = response.navigationData;
            if (navigationData) {
                setNavigationData(navigationData);
            }
        })();

        (async () => {
            const playerObj = await playerAPI.getSignedPlayer();
            setPlayer(playerObj);
        })();

        teacherSessionAPI.subscribeUser(sessionId, onMessage);
    }, [sessionId]);

    useEffect(() => {
        if (!joined) {
            return;
        }

        (async () => {
            await teacherSessionAPI.sendStudentAction(sessionId, studentName, { action: 'connected' });
        })();
    }, [joined, sessionId, studentName]);

    return (
      <Layout className="student-page" withHeader={false}>
          {
              joined
                ? (
                  <div className="student-container" ref={containerRef}>
                      <StudentSlide
                        slide={currentSlide}
                        player={player}
                        externalDestination={externalDestination}
                        playing={playing}
                        volume={volume}
                        seekedTime={seekedTime}
                        onStudentClick={handleStudentClick}
                      />
                  </div>
                )
                : <JoinSessionOverlay clickHandler={overlayClickHandler} />
          }
      </Layout>
    );
};

export default Student;
