import { Player, Media } from '../interfaces/player';

import SolsApiCall from './index';

type PlayerAPI = {
    getSignedPlayer: () => Promise<Player>;
    getSignedMedia: (id: string) => Promise<Media>;
};

const playerAPI: PlayerAPI = {
    async getSignedPlayer(): Promise<Player> {
        const response = await SolsApiCall(`/get_player`);

        return response.json();
    },

    async getSignedMedia(id): Promise<Media> {
        const response = await SolsApiCall(`/get_signed_media/${id}`);

        return response.json();
    },
}

export default playerAPI;
