import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import { Fab, Typography } from '@material-ui/core';
import { DoubleArrow as DoubleArrowIcon } from '@material-ui/icons';

import Layout from '../../Layout';
import StepNavigator, { STEP } from './StepNavigator';
import ReelItemsRadioGroup from './ReelItemsRadioGroup';

import themeAPI from '../../../api/theme';
import strandAPI from '../../../api/strand';
import setAPI from '../../../api/set';
import categoryAPI from '../../../api/category';

import {
    emptyTheme,
    emptyStrand,
    emptyCategory,
} from '../../../interfaces/defaults';
import { Set } from '../../../interfaces/set';
import { Strand } from '../../../interfaces/strand';
import { Theme } from '../../../interfaces/theme';
import { Category } from '../../../interfaces/category';

import './SelectSetStep.scss';

const SelectSetStep: React.FC = () => {
    const [category, setCategory] = useState<Category>({ ...emptyCategory });
    const [theme, setTheme] = useState<Theme>({ ...emptyTheme });
    const [strand, setStrand] = useState<Strand>({ ...emptyStrand });
    const [sets, setSets] = useState<Set[] | null>(null)
    const [selectedSet, setSelectedSet] = useState<Set | null>(null);

    const history = useHistory();
    const {
        categoryId,
        themeId,
        strandId,
    } = useParams();

    useEffect(() => {
        const fetchCategory = async (categoryId: string) => {
            setCategory(await categoryAPI.single(categoryId));
        };

        async function getTheme(themeId: string) {
            setTheme(await themeAPI.single(themeId));
        }

        async function getStrand(strandId: string) {
            setStrand(await strandAPI.single(strandId));
        }

        async function getSets(themeId: string, strandId: string) {
            setSets(await setAPI.listing(themeId, strandId))
        }

        // noinspection JSIgnoredPromiseFromCall
        fetchCategory(categoryId);
        // noinspection JSIgnoredPromiseFromCall
        getTheme(themeId);
        // noinspection JSIgnoredPromiseFromCall
        getStrand(strandId);
        // noinspection JSIgnoredPromiseFromCall
        getSets(themeId, strandId);
    }, [themeId, strandId, categoryId]);

    if (sets && sets.length === 1) {
        history.push(`/reel/create/${categoryId}/${themeId}/${strandId}/${sets[0].id}`);
    }

    if (!sets) {
        return (<></>);
    }

    return (
        <Layout className="select-set-page" title="Create Your REEL">
            <StepNavigator currentStep={STEP.SET} category={category} theme={theme} strand={strand} />

            <div className="section description-section">
                <Typography variant="body2" color="textPrimary">
                    <p>
                        Select a set below based on your student’s current developmental level and needs,
                        click on the accordion for detail of the emerging skills to be taught in each set.
                    </p>
                    <p>
                        For further information on how to select a set consult the Strand Selection Guide found at
                        {' '}
                        <a href="https://www.starautismsupport.com/" target="_blank" rel="nofollow noopener noreferrer">
                            www.starautismsupport.com
                        </a>.
                    </p>
                </Typography>
            </div>

            <div className="section set-select-section">
                {sets && (
                    <ReelItemsRadioGroup
                        items={sets}
                        onSelectItem={(set) => setSelectedSet(set)}
                    />
                )}
            </div>

            <Fab
                variant="extended"
                color="primary"
                aria-label="add"
                className="floating-fab"
                disabled={!selectedSet}
                href={`/reel/create/${categoryId}/${themeId}/${strandId}/${selectedSet ? selectedSet.id : ''}`}
            >
                Next
                <DoubleArrowIcon />
            </Fab>
        </Layout>
    );
};

export default SelectSetStep;
