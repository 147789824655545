import { LessonContent } from '../interfaces/reel';
import { SetContent } from '../interfaces/setContent';

import SolsApiCall from './index';

type SetContentAPI = {
    single: (id: string) => Promise<SetContent>;
    lessons: (id: string) => Promise<LessonContent[]>;
};

const setContentAPI: SetContentAPI = {
    async single(id): Promise<SetContent> {
        const response = await SolsApiCall(`/set_contents/${id}`);

        return response.json();
    },

    async lessons(id): Promise<LessonContent[]> {
        const response = await SolsApiCall(`/set_contents/${id}/lessons`);

        return response.json();
    },
};

export default setContentAPI;
