import React from 'react';

import {
    BrowserRouter,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';

import Reel from './components/reel';
import TeacherSession from './components/teacherSession';
import Student from './components/student';

import theme from './theme';

import './sass/app.scss';

const App: React.FC = () => (
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Switch>
                <Route path="/reel" component={Reel} />
                <Route path="/teacher-session/:sessionId" component={TeacherSession} strict exact />
                <Route path="/student/:sessionId/:studentName" component={Student} strict exact />
                <Route path="/" strict exact>
                    <Redirect to="/reel/create" />
                </Route>

                <Route render={() => <h1>Not Found</h1>} />
            </Switch>
        </BrowserRouter>
    </ThemeProvider>
);

export default App;
