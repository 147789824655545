import React from 'react';
import { Button } from '@material-ui/core'

interface Props {
    clickHandler: () => void,
}

const JoinSessionOverlay: React.FC<Props> = ({ clickHandler }) => (
    <div
        className="student-container clickable"
        onClick={() => clickHandler()}
    >
        <Button
            className="centered-button"
            variant="contained"
            color="primary"
        >
            <h1>Begin Session</h1>
        </Button>
    </div>
);

export default JoinSessionOverlay;
