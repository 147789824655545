import {
  Reel,
  ReelContent,
  ReelSelection,
} from '../interfaces/reel';

import SolsApiCall from './index';

type ReelAPI = {
  previewContent: (reelSelection: ReelSelection) => Promise<ReelContent>;
  reelContent: (id: string) => Promise<ReelContent>;
  single: (id: string) => Promise<Reel>;
  create: (themeId: string, reel: Reel) => Promise<Reel>;
  update: (id: string, reel: Reel) => Promise<Reel>;
};

const reelAPI: ReelAPI = {
  async previewContent(reelSelection): Promise<ReelContent> {
    const response = await SolsApiCall('/reels/preview_content', {
      method: 'POST',
      body: JSON.stringify({ reelSelection }),
    });

    return response.json();
  },

  async reelContent(id): Promise<ReelContent> {
    const response = await SolsApiCall(`/reels/${id}/content`);

    return response.json();
  },

  async single(id): Promise<Reel> {
    const response = await SolsApiCall(`/reels/${id}`);

    return response.json();
  },

  async create(themeId, reel): Promise<Reel> {
    const response = await SolsApiCall('/reels', {
      method: 'POST',
      body: JSON.stringify({
        ...reel,
        theme: `/themes/${themeId}`,
      }),
    });

    return response.json();
  },

  async update(id, reel): Promise<Reel> {
    const response = await SolsApiCall(`/reels/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        ...reel,
        theme: `/themes/${reel.theme.id}`,
      }),
    });

    return response.json();
  },
};

export default reelAPI;
