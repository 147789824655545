import { Category } from './category';
import { Theme } from './theme';
import { Strand } from './strand';
import { Set } from './set';
import {
  BlockContent,
  LessonContent,
  Reel,
} from './reel';
import { Media, Player } from './player';
import { SlideType } from './slideType';
import { StudentAction } from './teachingSession';

const emptyCategory: Category = {
  name: '',
  image: '',
};

const emptyTheme: Theme = {
  name: '',
  subtitle: '',
  image: '',
};

const emptyStrand: Strand = {
  name: '',
  description: '',
  sets: [],
};

const emptySet: Set = {
  name: '',
  description: '',
  strand: '',
};

const emptyReel: Reel = {
  name: '',
  labels: '',
  theme: {
    id: '',
    name: '',
    category: { name: '' },
  },
};

const emptyBlockContent: BlockContent = {
  id: '',
  name: '',
  setContentId: '',
  lessons: [],
  sortOrder: 0,
};

const emptyLessonContent: LessonContent = {
  id: '',
  name: '',
  sortOrder: 0,
  slides: [],
  activity: false,
  active: true,
};

const emptySlide: SlideType = {
  choices: [],
  image: '',
  name: '',
  sortOrder: 0,
}

const emptyStudentAction: StudentAction = {
  action: '',
  slide: '',
  top: 0,
  left: 0,
  date: new Date(),
  studentName: '',
}

const emptyPlayer: Player = {
  id: '',
  url: ''
}

const emptyMedia: Media = {
  url: ''
}

export {
  emptyCategory,
  emptyTheme,
  emptyStrand,
  emptySet,
  emptyReel,
  emptyBlockContent,
  emptyLessonContent,
  emptySlide,
  emptyStudentAction,
  emptyPlayer,
  emptyMedia,
};
