import React, {
    useEffect,
    useLayoutEffect,
    useReducer,
    useRef,
    useState,
} from 'react';

import { useParams, useHistory } from 'react-router-dom';

import {
    Button,
    MenuItem,
    MobileStepper,
    Select,
    Snackbar,
    Typography,
} from '@material-ui/core';
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    SpeakerNotes as SpeakerNotesIcon,
    Close as CloseIcon,
    SyncAlt as SyncAltIcon,
} from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';

import BlockNavigationTabs from './BlockNavigationTabs';
import Layout from '../Layout';
import ResizablePanel from '../shared/ResizablePanel';
import LessonNavigationStepper from './LessonNavigationStepper';
import Slide from './Slide';
import StudentSessionModal from './StudentSessionModal';

import playerAPI from '../../api/player';
import reelAPI from '../../api/reel';
import setAPI from '../../api/set';
import setContentAPI from '../../api/setContent';
import strandAPI from '../../api/strand';
import teacherSessionAPI from '../../api/teacherSession';
import themeAPI from '../../api/theme';

import { Player } from '../../interfaces/player';
import {BlockContent, LessonContent, ReelContent} from '../../interfaces/reel';
import { Set } from '../../interfaces/set';
import { Strand } from '../../interfaces/strand';
import { StudentAction } from '../../interfaces/teachingSession';
import { Theme } from '../../interfaces/theme';

import slideNavigationReducer, { ActionType, initialNavigationState } from './slideNavigationReducer';

import {
    emptyPlayer,
    emptySet,
    emptyStrand,
    emptyTheme,
} from '../../interfaces/defaults';

import './index.scss';
import {SlideType} from '../../interfaces/slideType';

declare global {
    interface Window {
        gtag: (command: string, commandParam: any, commandOptions?: any) => any;
    }
}

// @ts-ignore
const hideStudentButton = (window._env_ && window._env_.HIDE_STUDENT_VIEW) ? window._env_.HIDE_STUDENT_VIEW : process.env.HIDE_STUDENT_VIEW;

const TeacherSession: React.FC = () => {
    const [theme, setTheme] = useState<Theme>({ ...emptyTheme });
    const [set, setSet] = useState<Set>({ ...emptySet });
    const [strand, setStrand] = useState<Strand>({ ...emptyStrand });
    const [player, setPlayer] = useState<Player>({ ...emptyPlayer });
    const [latestStudentActions, setLatestStudentActions] = useState<StudentAction[]>([]);
    const [connectedStudentCount, setConnectedStudentCount] = useState<(number)>(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [openModal, setOpenModal] = useState(false);
    const [studentUrl, setStudentUrl] = useState('');
    const [reelHasNotes, setReelHasNotes] = useState(false);
    const [openNotes, setOpenNotes] = useState(false);
    const [notesDirection, setNotesDirection] = useState<'n' | 'w'>('w');
    const [slideContainerHeight, setSlideContainerHeight] = useState(0);

    const slideContainerRef = useRef<HTMLDivElement|null>(null);

    const startedTime = useRef<number>(0);
    const connections = useRef<any>({});

    const [navState, dispatch] = useReducer(slideNavigationReducer, initialNavigationState);

    const { sessionId } = useParams();

    const history = useHistory();

    const lessonSlideCountReducer = (lessonCounter: number, lesson: LessonContent): number =>
      lessonCounter + lesson.slides.length;

    const blockSlideCountReducer = (blockCounter: number, block: BlockContent): number =>
      blockCounter + block.lessons.reduce(lessonSlideCountReducer, 0);

    const getSlideCount = (): number => navState.reelContent.blocks.reduce(blockSlideCountReducer, 0);

    const getPastBlocksSlideCount = (): number => navState.reelContent.blocks
      .filter((block, blockIndex) => blockIndex < navState.blockIndex)
      .reduce(blockSlideCountReducer, 0);

    const getPastLessonSlideCount = (): number => navState.block.lessons
      .filter((lesson, lessonIndex) => lessonIndex < navState.lessonIndex)
      .reduce(lessonSlideCountReducer, 0);

    const getCurrentSlideCount = (): number =>
      getPastBlocksSlideCount() + getPastLessonSlideCount() + navState.slideIndex;

    const endSession = async () => {
        // GA track session time
        const time = (new Date()).getTime() - startedTime.current;
        window.gtag('event', 'session_ended');
        window.gtag('event', 'timing_complete', {
            name: 'session',
            value: time
        });

        await teacherSessionAPI.endSession(sessionId);
        localStorage.removeItem(sessionId);

        history.push('/');
    };

    const createStudentSession = async (studentName: string) => {
        const cleanStudentName = studentName && studentName.length > 0
          ? studentName
          : `Student-${connectedStudentCount + 1}`;

        await teacherSessionAPI.addStudentToSession(sessionId, cleanStudentName);

        setStudentUrl(`${document.location.origin}/student/${sessionId}/${encodeURI(cleanStudentName)}`);
    };

    const handleAdvancedMenu = (event: React.ChangeEvent<{ value: unknown }>) => {
        switch (event.target.value as string) {
            case 'student':
                setOpenModal(true)
                break;
            case 'end':
                endSession();
                break;
        }
    };

    const renderPanelContent = () => (
        <>
            <div className="panel-controls">
                <Button
                    color="primary"
                    variant="contained"
                    className="panel-toggle-position-button"
                    onClick={() => setNotesDirection(notesDirection === 'w' ? 'n' : 'w')}
                >
                    Right <SyncAltIcon /> Bottom
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    className="panel-close-button"
                    onClick={() => setOpenNotes(false)}
                >
                    <CloseIcon />
                </Button>
            </div>
            <div className="notes" dangerouslySetInnerHTML={{__html: navState.slide.notes ?? ''}} />
        </>
    );

    useEffect(() => {
        const slideHasNotes = (slide: SlideType): boolean => {
            if (slide.notes) return true;
            return slide.choices.some((choice) => {
               if (choice.destinationSlide) {
                   return slideHasNotes(choice.destinationSlide as SlideType);
               }

               return false;
            });
        };

        const isReelHasNotes = ({ blocks }: ReelContent): boolean =>
            blocks.some(({ lessons }) => (
                lessons.some(({ slides }) => slides.some(slideHasNotes))
            )
        );

        (async () => setPlayer(await playerAPI.getSignedPlayer()))();
        (async () => {
            const localStorageSession = localStorage.getItem(sessionId);
            if (localStorageSession) {
                const cachedData = JSON.parse(localStorageSession);

                if (cachedData) {
                    setTheme(cachedData.theme);
                    setReelHasNotes(isReelHasNotes(cachedData.reel));

                    dispatch({ type: ActionType.loadReel, reelContent: cachedData.reel });

                    return;
                }
            }

            const session = await teacherSessionAPI.single(sessionId);
            const reel = await reelAPI.single(session.reel.id);

            const theme = await themeAPI.single(reel.theme.id);
            const reelContent = await reelAPI.reelContent(session.reel.id);

            setReelHasNotes(isReelHasNotes(reelContent));
            setTheme(theme);

            dispatch({ type: ActionType.loadReel, reelContent });
        })();
    }, [sessionId]);

    useEffect(() => {
        const handleStudentActions = (students: any) => {
            const studentActions = Object.keys(students).map((student) => ({
                ...students[student],
                studentName: student
            }));

            setLatestStudentActions(studentActions);

            const newStudents = studentActions.filter((studentAction) => {
                if (studentAction.action !== 'connected') {
                    return false;
                }

                const name = studentAction.studentName;
                const lastSeen = connections.current[name];
                if (lastSeen && new Date(lastSeen.date).getTime() >= new Date(studentAction.date).getTime()) {
                    return false;
                }

                connections.current[name] = studentAction;
                return true;
            })
              .map((studentAction) => studentAction.studentName);

            if (newStudents.length > 1) {
                setSnackbarMessage(`The students ${newStudents.join(', ')} are online.`);
            } else if (newStudents.length === 1) {
                setSnackbarMessage(`The student ${newStudents[0]} is online.`);
            }

            setConnectedStudentCount(studentActions.length);
            setOpenSnackbar(newStudents.length > 0);
        };

        const onMessage = (ev: any) => {
            const data = JSON.parse(ev.data);

            if (data.navigationData.students) {
                const { students } = data.navigationData;
                handleStudentActions(students);
            }
        };

        teacherSessionAPI.subscribeUser(sessionId, onMessage);
    }, [sessionId]);

    useEffect(() => {
        (async () => {
            if (!navState.slide.id) {
                return;
            }

            const navigationData = {
                currentSlide: `/slides/${navState.slide.id}`,
                externalDestination: '',
                videoSlideState: navState.slide.video
                  ? {
                      playing: false,
                      volume: 0,
                      seekedTime: 0,
                  }
                  : null,
            };

            await teacherSessionAPI.updateNavigation(sessionId, navigationData);
        })();
    }, [navState.slide.id, navState.slide.video, sessionId]);

    useEffect(() => {
        if (!navState.block.id) {
            return;
        }

        (async () => {
            const setContent = await setContentAPI.single(navState.block.setContentId);
            const fetchedSet = await setAPI.single(setContent.set.split('/')[2]);
            const fetchedStrand = await strandAPI.single(fetchedSet.strand.split('/')[2]);

            setSet(fetchedSet);
            setStrand(fetchedStrand);
        })();
    }, [navState.block]);

    useEffect(() => {
        if (!theme.id) {
            return;
        }

        // GA track theme selection
        window.gtag('event', 'theme', { theme_selection: theme.name });

        // GA track the session time
        startedTime.current = (new Date()).getTime();
        window.gtag('event', 'session_started');
    }, [theme]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, []);

    useLayoutEffect(() => {
        const slideContainer = slideContainerRef.current;
        if (!slideContainer) return;

        // Calculate height based on 16/9 ratio
        const rect = slideContainer.getBoundingClientRect();
        setSlideContainerHeight(rect.width / (16.0 / 9.0));
    }, [slideContainerRef]);

    return (
        <Layout className="teacher-session" title="Your REEL">
            <div className="slide-and-navigation">
                <BlockNavigationTabs state={navState} dispatch={dispatch} />
                <LessonNavigationStepper state={navState} dispatch={dispatch} />

                <div
                    className="slide-and-notes"
                    style={{
                        height: (reelHasNotes && openNotes && notesDirection === 'n') ? slideContainerHeight + 200 : '',
                    }}
                >
                    <div className="slide-body">
                        <div className="slide-content panel">
                            <div
                                className="slide-container"
                                ref={slideContainerRef}
                                style={{
                                    height: (reelHasNotes && openNotes && notesDirection === 'n')
                                        ? ''
                                        : slideContainerHeight,
                                }}
                            >
                                <Slide
                                    slide={navState.slide}
                                    fitWidth={!openNotes || (openNotes && notesDirection === 'w')}
                                    dispatch={dispatch}
                                    player={player}
                                    latestStudentActions={latestStudentActions}
                                    onUpdateVideoState={async (videoState: any) => {
                                        await teacherSessionAPI.updateNavigation(sessionId, {
                                            videoSlideState: videoState,
                                        });
                                    }}
                                    onOpenExternalDestination={async (externalDestination: string) => {
                                        await teacherSessionAPI.updateNavigation(sessionId, {
                                            externalDestination,
                                        });
                                    }}
                                />
                            </div>

                            <div className={`slide-navigation${reelHasNotes ? ' with-notes' : ''}`}>
                                <MobileStepper
                                    variant="progress"
                                    steps={getSlideCount()}
                                    activeStep={getCurrentSlideCount()}
                                    position="static"
                                    backButton={
                                        <Button
                                            color="primary"
                                            onClick={() => dispatch({ type: ActionType.previousSlide })}
                                            variant="contained"
                                        >
                                            <KeyboardArrowLeft />
                                            Back
                                        </Button>
                                    }
                                    nextButton={
                                        <Button
                                            color="primary"
                                            onClick={() => dispatch({ type: ActionType.nextSlide })}
                                            variant="contained"
                                        >
                                            Next
                                            <KeyboardArrowRight />
                                        </Button>
                                    }
                                />

                                {reelHasNotes && (
                                    <div className="open-notes-section">
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            startIcon={<SpeakerNotesIcon />}
                                            onClick={() => setOpenNotes(true)}
                                            disabled={openNotes}
                                        >
                                            OPEN NOTES
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>

                        {reelHasNotes && openNotes && notesDirection === 'w' && (
                            <ResizablePanel direction={notesDirection} style={{ maxHeight: slideContainerHeight }}>
                                <div className="sidebar panel">
                                    {renderPanelContent()}
                                </div>
                            </ResizablePanel>
                        )}
                    </div>

                    {reelHasNotes && openNotes && notesDirection === 'n' && (
                        <ResizablePanel direction={notesDirection}>
                            <div className="footer panel">
                                {renderPanelContent()}
                            </div>
                        </ResizablePanel>
                    )}
                </div>
            </div>

            <div className="section state-section">
                <div className="reel-metadata theme-data">
                    <Typography variant="body2" color="textPrimary">THEME:</Typography>
                    <Typography variant="body1" color="textPrimary">{theme.name}</Typography>
                </div>

                <div className="reel-metadata strand-data">
                    <Typography variant="body2" color="textPrimary">STRAND:</Typography>
                    <Typography variant="body1" color="textPrimary">{strand.name}</Typography>
                </div>

                <div className="reel-metadata set-data">
                    <Typography variant="body2" color="textPrimary">SET:</Typography>
                    <Typography variant="body1" color="textPrimary">{set.name}</Typography>
                </div>

                <div className="button-section">
                    <Select
                        id="select"
                        value="default"
                        onChange={(e) => handleAdvancedMenu(e)}
                    >
                        <MenuItem className='hidden' disabled value="default">Advanced</MenuItem>
                        {!hideStudentButton && (
                            <MenuItem value="student">Create Student URL - Beta</MenuItem>
                        )}
                        <MenuItem value="end">End Session</MenuItem>
                    </Select>
                </div>
            </div>

            {!hideStudentButton && (
                <StudentSessionModal
                    openModal={openModal}
                    onClose={() => {
                        setStudentUrl('');
                        setOpenModal(false);
                    }}
                    studentUrl={studentUrl}
                    submitForm={createStudentSession}
                    onCopyURL={() => {
                        setSnackbarMessage('URL has been copied');
                        setOpenSnackbar(true);
                    }}
                />
            )}

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    severity="info"
                >
                    <div>{snackbarMessage}</div>
                </MuiAlert>
            </Snackbar>
        </Layout>
    );
};

export default TeacherSession;
