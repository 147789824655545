import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { Fab, Typography } from '@material-ui/core';
import { DoubleArrow as DoubleArrowIcon } from '@material-ui/icons';

import Layout from '../../Layout';
import StepNavigator, { STEP } from './StepNavigator';
import ReelItemCard from './ReelItemCard';

import categoryAPI from '../../../api/category';
import themeAPI from '../../../api/theme';

import { Category } from '../../../interfaces/category';
import { Theme } from '../../../interfaces/theme';
import { emptyCategory } from '../../../interfaces/defaults';

import './SelectThemeStep.scss';

const SelectThemeStep: React.FC = () => {
    const [category, setCategory] = useState<Category>({ ...emptyCategory });
    const [themes, setThemes] = useState<Array<Theme>>([]);
    const [selectedTheme, setSelectedTheme] = useState<Theme | null>(null);

    const { categoryId } = useParams();

    const history = useHistory();

    useEffect(() => {
        (async () => setCategory(await categoryAPI.single(categoryId)))();
        (async () => setThemes(await themeAPI.listing(categoryId)))();
    }, [categoryId]);

    if (themes.length === 1) {
        history.push(`/reel/create/${categoryId}/${themes[0].id}`);
    }

    if (themes.length <= 1) {
        return (<></>)
    }

    return (
        <Layout className="select-theme-page" title="Create Your REEL">
            <StepNavigator currentStep={STEP.THEME} category={category} />

            <div className="section description-section">
                <Typography variant="body1" color="textPrimary">
                    Welcome to STAR Online Learning System REEL. With REEL, teachers tailor interactive
                    presentations and activities to meet the specific needs of each student.
                </Typography>
                <Typography variant="body1" color="textPrimary">
                    Select a theme below to begin developing a presentation based on your student’s strand and skill level.
                </Typography>
            </div>


            <div className="section theme-select-section">
                {themes.map((theme) => (
                    <div key={theme.id} className="theme-grid-item">
                        <ReelItemCard
                            item={theme}
                            active={(selectedTheme !== null) && (selectedTheme.id === theme.id)}
                            onClick={() => {
                                if (selectedTheme && selectedTheme.id === theme.id) {
                                    setSelectedTheme(null);
                                    return;
                                }

                                setSelectedTheme(theme);
                            }}
                        />
                    </div>
                ))}
            </div>

            <Fab
                variant="extended"
                color="primary"
                aria-label="add"
                className="floating-fab"
                disabled={!selectedTheme}
                href={`/reel/create/${categoryId}/${selectedTheme ? selectedTheme.id : ''}`}
            >
                Next
                <DoubleArrowIcon />
            </Fab>
        </Layout>
    );
};

export default SelectThemeStep;
