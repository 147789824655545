import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
    container: {
        display: 'flex',
        alignItems: 'stretch',
        flexFlow: (opts : any) => opts.isHorizontal ? 'row nowrap' : 'column nowrap',
    },
    resizeHandle: {
        background: 'white',
        border: '2px solid lightgray',
        borderRadius: '8px',
        textAlign: 'center',
        zIndex: 10,
        overflow: 'hidden',
        display: 'flex',
        cursor: (opts : any) => opts.isHorizontal ? 'ew-resize' : 'ns-resize',
        width: (opts : any) => opts.isHorizontal ? '12px' : '50px',
        height: (opts : any) => opts.isHorizontal ? '50px' : '12px',
        alignItems: (opts : any) => opts.isHorizontal ? 'center' : 'initial',
        justifyContent: (opts : any) => opts.isHorizontal ? 'initial' : 'center',
        '& > span': {
            display: 'inline-block',
            overflow: 'hidden',
            fontSize: '12px',
            fontWeight: 'bold',
            fontFamily: 'sans-serif',
            letterSpacing: '1px',
            color: '#b3b3b3',
            textShadow: '1px 0 1px rgb(90, 90, 90)',
            textAlign: (opts : any) => opts.isHorizontal ? 'initial' : 'center',
            lineHeight: (opts : any) => opts.isHorizontal ? '4px' : '12px',
            marginTop: (opts : any) => opts.isHorizontal ? '0' : '-3px',
        },
        '& > span::after': {
            content: (opts : any) => opts.isHorizontal ? '. . . . . . . .' : '......',
        },
    },
    resizeBar: {
        background: 'transparent',
        display: 'flex',
        zIndex: 10,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        cursor: (opts : any) => opts.isHorizontal ? 'ew-resize' : 'ns-resize',
        width: (opts : any) => opts.isHorizontal ? '20px' : 'initial',
        height: (opts : any) => opts.isHorizontal ? 'initial' : '20px',
        marginLeft: (opts : any) => opts.isHorizontal ? '-10px' : 'initial',
        marginRight: (opts : any) => opts.isHorizontal ? '-10px' : 'initial',
        marginTop: (opts : any) => opts.isHorizontal ? 'initial' : '-10px',
        marginBottom: (opts : any) => opts.isHorizontal ? 'initial' : '-10px',
    },
    resizeContent: {
        flexGrow: 1,
        alignSelf: 'stretch',
        display: 'flex',
        flexFlow: (opts : any) => opts.isHorizontal ? 'row' : 'column',
    },
});
