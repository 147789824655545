import { StrandSets } from '../interfaces/strandSets';
import { Theme } from '../interfaces/theme';

import SolsApiCall from './index';

type ThemeAPI = {
    listing: (categoryId: string) => Promise<Theme[]>;
    single: (id: string) => Promise<Theme>;
    getStrandSets: (id: string) => Promise<StrandSets>;
};

const themeAPI: ThemeAPI = {
    async listing(categoryId): Promise<Theme[]> {
        const response = await SolsApiCall(`/themes?category=${categoryId}`);

        return response.json();
    },

    async single(id): Promise<Theme> {
        const response = await SolsApiCall(`/themes/${id}`);

        return response.json();
    },

    async getStrandSets(id): Promise<StrandSets> {
        const response = await SolsApiCall(`/themes/${id}/strands_sets`);

        return response.json();
    }
}

export default themeAPI;
