import React, { Dispatch } from 'react';

import {
    Stepper,
    Step,
    StepButton,
    StepLabel,
    Tooltip
} from '@material-ui/core';

import {FiberManualRecord} from '@material-ui/icons';

import {
    ActionType,
    NavigationActionTypes,
    NavigationState,
} from './slideNavigationReducer';

import './LessonNavigationStepper.scss';

interface Props {
    state: NavigationState,
    dispatch: Dispatch<NavigationActionTypes>
}

const LessonNavigationStepper: React.FC<Props> = ({ state, dispatch }) => {
    let stepCounter = 0;
    return (
    <Stepper nonLinear alternativeLabel activeStep={state.lessonIndex} className="lesson-stepper-container">
        {state.block.lessons.map((lesson, index) => {
            if(!lesson.activity) {
                stepCounter++;

                return (
                    <Step key={lesson.id}>
                        <StepButton
                            onClick={() => dispatch({ type: ActionType.goToLessonIndex, index })}
                            icon={stepCounter}
                        >
                            <StepLabel>{lesson.name}</StepLabel>
                        </StepButton>
                    </Step>
                );
            }

            return (
                <Step key={lesson.id} className={`${lesson.activity ? 'activity': ''}`}>
                    <Tooltip title={lesson.name}>
                        <StepButton
                            onClick={() => dispatch({ type: ActionType.goToLessonIndex, index })}
                            icon={<FiberManualRecord fontSize="small" />}
                        >
                        </StepButton>
                    </Tooltip>
                </Step>
            )})}
    </Stepper>
)};

export default LessonNavigationStepper;
