import React, { useState } from 'react';

import {
    Typography,
    Modal,
    TextField,
    Paper,
    Button,
    IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import './StudentSessionModal.scss';

interface Props {
    openModal: boolean,
    onClose: Function,
    studentUrl: string,
    submitForm: Function,
    onCopyURL: Function,
}

const StudentSessionModal: React.FC<Props> = ({
    openModal,
    onClose,
    studentUrl,
    submitForm,
    onCopyURL,
}) => {
    const [studentName, setStudentName] = useState('');

    return (
        <Modal
            open={openModal}
            onClose={() => onClose()}
            aria-labelledby="student-session-modal-title"
        >
            <div className="student-session-modal">
                <Paper>
                    <div className="modal-header">
                        <Typography id="student-session-modal-title" variant="h5">Create Student Session</Typography>
                        <IconButton onClick={() => onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <form className="student-session-form" onSubmit={(e) => {
                        e.preventDefault();
                        submitForm(studentName);
                    }}>
                        <TextField
                            label="Session or Student ID"
                            variant="filled"
                            fullWidth
                            value={studentName}
                            onChange={(e) => setStudentName(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Generate Student Link
                        </Button>

                        <TextField
                            label="Student Session URL"
                            variant="filled"
                            fullWidth
                            value={studentUrl}
                            InputProps={{ readOnly: true }}
                        />

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                navigator.clipboard.writeText(studentUrl);
                                onCopyURL();
                            }}
                        >
                            Copy URL
                        </Button>
                    </form>
                </Paper>
            </div>
        </Modal>
    );
};

export default StudentSessionModal;