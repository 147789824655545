import { Category } from '../interfaces/category';

import SolsApiCall from './index';

type CategoryAPI = {
    listing: (enabled: boolean) => Promise<Category[]>;
    single: (id: string) => Promise<Category>;
};

const categoryAPI: CategoryAPI = {
    async listing(enabled): Promise<Category[]> {
        const response = await SolsApiCall(`/categories?enabled=${enabled}`);

        return response.json();
    },

    async single(id): Promise<Category> {
        const response = await SolsApiCall(`/categories/${id}`);

        return response.json();
    },
};

export default categoryAPI;
