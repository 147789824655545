import React, {
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { DraggableCore } from 'react-draggable';

import $ from 'cash-dom';

import useStyles from './resizablePanelStyles';

interface Props {
    direction:  'n' | 's' | 'e' | 'w',
    containerClass?: string,
    style?: React.CSSProperties,
    children: ReactNode,
}

const ResizablePanel: React.FC<Props> = ({
    direction,
    containerClass = '',
    style = {},
    children,
}) => {
    const [size, setSize] = useState(0);
    const [mounted, setMounted] = useState(false);

    const wrapperRef = useRef<HTMLDivElement|null>(null);
    const contentRef = useRef<HTMLDivElement|null>(null);

    const testIsHorizontal = useCallback((): boolean => (
        direction === 'w' || direction === 'e'
    ), [direction]);

    const validateSize = useCallback(() => {
        const isHorizontal = testIsHorizontal();
        const content = contentRef.current;
        const wrapper = wrapperRef.current;
        if (!content || !wrapper) return;

        const actualContent = content.children[0];
        const containerParent = wrapper.parentElement;
        if (!actualContent || !containerParent) return;

        // Or if our size doesn't equal the actual content size, then we
        // must have pushed past the min size of the content, so resize back
        // let minSize = isHorizontal ? $(actualContent).outerWidth(true) : $(actualContent).outerHeight(true);
        let minSize = isHorizontal
            ? actualContent.scrollWidth
            : actualContent.scrollHeight;

        const margins = isHorizontal
            ? $(actualContent).outerWidth(true) - $(actualContent).outerWidth()
            : $(actualContent).outerHeight(true) - $(actualContent).outerHeight();
        minSize += margins;

        if (size !== minSize) {
            setSize(minSize);
        } else {
            // If our resizing has left the parent container's content overflowing
            // then we need to shrink back down to fit
            let overflow = isHorizontal
                ? containerParent.scrollWidth - containerParent.clientWidth
                : containerParent.scrollHeight - containerParent.clientHeight;

            if (overflow) {
                setSize(isHorizontal
                    ? actualContent.clientWidth - overflow
                    : actualContent.clientHeight - overflow);
            }
        }
    }, [size, testIsHorizontal]);

    const handleDrag = (e: any, ui: any) => {
        const factor = direction === 'e' || direction === 's' ? -1 : 1;

        // modify the size based on the drag delta
        const delta = testIsHorizontal() ? ui.deltaX : ui.deltaY;
        setSize(Math.max(10, size - delta * factor));
    };

    const handleDragEnd = () => {
        validateSize();
    };

    useEffect(() => {
        if (mounted) return;

        const content = contentRef.current;
        if (!content) return;

        const actualContent = content.children[0];
        const initialSize = testIsHorizontal()
            ? $(actualContent).outerWidth(true)
            : $(actualContent).outerHeight(true);

        // Initialize the size value based on the content's current size
        setSize(initialSize);
        validateSize();

        setMounted(true);
    }, [mounted, testIsHorizontal, validateSize]);

    const isHorizontal = testIsHorizontal();

    const classes = useStyles({ isHorizontal });

    const contentStyle = isHorizontal ? { width: `${size}px` } : { height: `${size}px` };

    const content = [
        <div
            key="content"
            ref={contentRef}
            className={classes.resizeContent}
            style={contentStyle}
        >
            {React.Children.only(children)}
        </div>
    ];

    const dragHandlers = {
        onDrag: handleDrag,
        onStop: handleDragEnd,
    };

    const handle = (
        <DraggableCore key="handle" {...dragHandlers}>
            <div className={classes.resizeBar}>
                <div className={classes.resizeHandle}>
                    <span />
                </div>
            </div>
        </DraggableCore>
    );

    // Insert the handle at the beginning of the content if our direction is west or north
    if (direction === 'w' || direction === 'n') {
        content.unshift(handle);
    } else {
        content.push(handle);
    }

    const containerStyle = { ...style } || {};
    if (size !== 0) {
        containerStyle.flexGrow = 0;
        containerStyle[isHorizontal ? 'width' : 'height'] = 'auto';
    }

    return (
        <div
            ref={wrapperRef}
            className={`${classes.container} ${containerClass}`}
            style={containerStyle}
        >
            {content}
        </div>
    );
};

export default ResizablePanel;
