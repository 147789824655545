import React from 'react';

import RadioGroup from '@material-ui/core/RadioGroup';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import './ReelItemsRadioGroup.scss';

interface Props {
    items: any[],
    onSelectItem?: (item: any) => void,
}

const noOp = () => {};

const ReelItemsRadioGroup: React.FC<Props> = ({ items, onSelectItem = noOp }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        const selected = items.find((item) => item.id === value);

        if (selected) {
            onSelectItem(selected);
        }
    };

    return (
        <Paper>
            <div className="reel-items-radio-group">
                <RadioGroup aria-label="reel-item" name="reel-item" onChange={handleChange}>
                    {items.map((item) => (
                        <Accordion key={item.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <FormControlLabel
                                    aria-label={item.id}
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    value={item.id}
                                    control={<Radio className="reel-item-radio-button" />}
                                    label={item.name}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography
                                    variant="body1"
                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </RadioGroup>
            </div>
        </Paper>
    );
};

export default ReelItemsRadioGroup;
