import React, {
    Dispatch,
    useEffect,
    useRef,
    useState,
} from 'react';

import ReactJWPlayer from 'react-jw-player';

import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

import { Player, Media } from '../../interfaces/player';
import { SlideType } from '../../interfaces/slideType';
import { SlideChoice } from '../../interfaces/slideChoice';
import { StudentAction } from '../../interfaces/teachingSession';

import { emptyMedia } from '../../interfaces/defaults';

import { ActionType, NavigationActionTypes } from './slideNavigationReducer';

import playerAPI from '../../api/player';

declare global {
    interface Window {
        jwplayer: (id: string) => any;
    }
}

interface Props {
    slide: SlideType,
    dispatch: Dispatch<NavigationActionTypes>
    player: Player,
    latestStudentActions?: StudentAction[]
    onUpdateVideoState: Function,
    onOpenExternalDestination: Function,
    fitWidth?: boolean,
}

const Slide: React.FC<Props> = ({
    slide,
    dispatch,
    player,
    latestStudentActions,
    onUpdateVideoState,
    onOpenExternalDestination,
    fitWidth= true,
}) => {
    const [slideChoices, setSlideChoices] = useState<SlideChoice[]>([]);
    const [videoData, setVideoData] = useState<Media>({...emptyMedia})
    const [loadingContent, setLoadingContent] = useState(true);

    const videoState = useRef({
        playing: false,
        volume: 0,
        seekedTime: 0,
    });

    const generateHotspotStyles = (hotspot: SlideChoice) => ({
        top: `${hotspot.topPosition/10}%`,
        left: `${hotspot.leftPosition/10}%`,
        width: `${hotspot.width/10}%`,
        height: `${hotspot.height/10}%`,
    });

    const generateStudentActionStyles = (action: StudentAction) => ({
        top: `${action.top / 10}%`,
        left: `${action.left / 10}%`
    });

    const navigateSlideChoice = async (choice: SlideChoice) => {
        if (choice.destinationSlide) {
            dispatch({
                type: ActionType.setSlide,
                slide: choice.destinationSlide as SlideType,
            });
        } else if (choice.externalDestination) {
            onOpenExternalDestination(choice.externalDestination);
            window.open(choice.externalDestination, '_blank');
        }
    }

    useEffect(() => {
        (async () => {
            if (slide.video) {
                setVideoData(await playerAPI.getSignedMedia(slide.video));
            }
        })();

        setSlideChoices(slide.choices);
    }, [slide]);

    useEffect(() => {
        videoState.current.playing = false;
        videoState.current.volume = 0.0;
        videoState.current.seekedTime = 0.0;
    }, [slide.video]);

    return (
        <div className="section slide">
            <Paper square>
                {slide.video && loadingContent && (
                    <CircularProgress className="progress-spinner" />
                )}
                {slide.video && videoData.url && (
                    <ReactJWPlayer
                        playerId={player.id}
                        playerScript={`${player.url}`}
                        playlist={videoData.url}
                        onPlay={()=> {
                            videoState.current.playing = true;
                            videoState.current.volume = window.jwplayer(player.id).getVolume() * 1000;
                            onUpdateVideoState(videoState.current);
                        }}
                        onResume={() => {
                            videoState.current.playing = true;
                            onUpdateVideoState(videoState.current);
                        }}
                        onPause={() => {
                            videoState.current.playing = false;
                            onUpdateVideoState(videoState.current);
                        }}
                        onSeeked={() => {
                            videoState.current.seekedTime = Math.floor(window.jwplayer(player.id).getPosition() * 1000);
                            onUpdateVideoState(videoState.current);
                        }}
                        onVolume={() => {
                            videoState.current.volume = Math.floor(window.jwplayer(player.id).getVolume() * 1000);
                            onUpdateVideoState(videoState.current);
                        }}
                        onReady={() => setLoadingContent(false)}
                        customProps={{
                            autostart: false,
                            mute: false,
                            volume: 50,
                        }}
                    />
                )}
                {!slide.video && (
                    <>
                        <img
                            className={fitWidth ? 'fit-width' : 'fit-height'}
                            src={slide.image}
                            alt={`Slide ${slide.name}`}
                        />
                        {slideChoices.length > 0 && (
                            <div>
                                {slideChoices.map((choice) => (
                                    // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
                                    <a
                                        key={choice.id}
                                        className='slide-choice'
                                        style={generateHotspotStyles(choice)}
                                        onClick={() => navigateSlideChoice(choice)}
                                    />
                                ))}
                            </div>
                        )}
                    </>
                )}
                {latestStudentActions && (
                    <div>
                        {latestStudentActions.map((action) => (action.action !== 'click' || action.slide !== slide.id)
                            ? null
                            : <Chip
                                key={`student-action-${action.studentName}`}
                                variant="default"
                                color="secondary"
                                size="small"
                                className="student-action"
                                style={generateStudentActionStyles(action)}
                                label={action.studentName}
                            />
                        )}
                    </div>
                )}
            </Paper>
        </div>
    );
}

export default Slide;
