import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import StudentVideoPlayer from './StudentVideoPlayer';
import IntroVideo from './IntroVideo';

import { SlideType } from '../../interfaces/slideType';
import { Media, Player } from '../../interfaces/player';

import playerAPI from '../../api/player';

import { emptyMedia } from '../../interfaces/defaults';

interface Props {
    slide: SlideType,
    player: Player,
    externalDestination: string
    playing: boolean,
    volume: number,
    seekedTime: number,
    onStudentClick: Function,
}

interface PlayerInstance {
    play: () => void,
    pause: () => void,
    setMute: (state: boolean) => void,
    setVolume: (volume: number) => void,
    seek: (position: number) => void,
    isPlaying: () => boolean,
}

const StudentSlide: React.FC<Props> = ({
    slide,
    player,
    externalDestination,
    playing,
    volume,
    seekedTime,
    onStudentClick,
}) => {
    const [videoData, setVideoData] = useState<Media>({ ...emptyMedia });
    const [playerIsReady, setPlayerIsReady] = useState<boolean>(false);

    const playerRef = useRef<PlayerInstance>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const handleSlideClick = async (evt: any) => {
        const viewport = evt.target.getBoundingClientRect();
        onStudentClick({
            x: (evt.pageX - viewport.x) / viewport.width,
            y: (evt.pageY - viewport.y) / viewport.height,
        });
    };

    const generateIframeStyles = () => {
        return {
            height: document.body.clientHeight
        }
    }

    useEffect(() => {
        const loadSignedMedia = async () => {
            if (slide.video) {
                setVideoData(await playerAPI.getSignedMedia(slide.video));
            }
        }

        // noinspection JSIgnoredPromiseFromCall
        loadSignedMedia();
    }, [slide.video]);

    useEffect(() => {
        const playerComponent = playerRef.current;
        if (playerComponent && playerIsReady) {
            if (playing && !playerComponent.isPlaying()) {
                playerComponent.play();
            }

            if (!playing && playerComponent.isPlaying()) {
                playerComponent.pause();
            }
        }
    }, [playerIsReady, playing]);

    useEffect(() => {
        const playerComponent = playerRef.current;
        if (playerComponent && playerIsReady) {
            playerComponent.setVolume(volume / 1000.0);
        }
    }, [playerIsReady, volume]);

    useEffect(() => {
        const playerComponent = playerRef.current;
        if (playerComponent && playerIsReady) {
            playerComponent.seek(seekedTime / 1000.0);
        }
    }, [playerIsReady, seekedTime]);

    return (
        <div
            className="student-slide-holder"
            ref={containerRef}
            style={{
                // Top margin is half of the space remaining between the vertical viewport and the slide height
                // Implemented this way because SASS has issues with this line
                margin: 'max((100vh - (var(--slide-height)))/2, 0px) auto 0',
            }}
        >
            <IntroVideo player={player} />
            {externalDestination && (
                <iframe
                    className="slide"
                    style={generateIframeStyles()}
                    title='External Destination'
                    src={externalDestination}
                    onClick={handleSlideClick}
                />
            )}
            {!externalDestination && slide.video && videoData.url && (
                <StudentVideoPlayer
                    ref={playerRef}
                    videoData={videoData}
                    player={player}
                    onPlay={() => {
                        const playerComponent = playerRef.current;
                        if (!playing && playerComponent) {
                            playerComponent.pause();
                        }

                        return playing;
                    }}
                    onReady={() => {
                        setPlayerIsReady(true);
                    }}
                    onClick={handleSlideClick}
                />
            )}
            {!externalDestination && !slide.video && (
                <img
                    className="slide"
                    src={slide.image}
                    alt={`Slide ${slide.name}`}
                    onClick={handleSlideClick}
                />
            )}
        </div>
    );
};

export default StudentSlide;
