import React from 'react';

import { Switch, Route } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { isMobile } from 'react-device-detect';

import SelectCategoryStep from './create/SelectCategoryStep';
import SelectThemeStep from './create/SelectThemeStep';
import SelectStrandStep from './create/SelectStrandStep';
import SelectSetStep from './create/SelectSetStep';
import PreviewReel from './create/PreviewReel';

import './index.scss';

const Reel: React.FC = () => (
    // @ts-ignore
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <Switch>
            <Route path="/reel/create" component={SelectCategoryStep} strict exact />
            <Route path="/reel/create/:categoryId" component={SelectThemeStep} strict exact />
            <Route path="/reel/create/:categoryId/:themeId" component={SelectStrandStep} strict exact />
            <Route path="/reel/create/:categoryId/:themeId/:strandId" component={SelectSetStep} strict exact />
            <Route path="/reel/create/:categoryId/:themeId/:strandId/:setId" component={PreviewReel} strict exact />
            <Route
              path="/reel/public/:reelId"
              component={() => <PreviewReel paraprofessional />}
              strict
              exact
            />
            <Route
                path="/reel/create/paraprofessional/:categoryId/:themeId/:strandId/:setId"
                component={() => <PreviewReel paraprofessional />}
                strict
                exact
            />
            <Route
                path="/reel/create/caregiver/:categoryId/:themeId/:strandId/:setId"
                component={() => <PreviewReel paraprofessional />}
                strict
                exact
            />

            <Route render={() => <h1>Not Found</h1>} />
        </Switch>
    </DndProvider>
);

export default Reel;
