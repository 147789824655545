import React, { useEffect, useState } from 'react';

import { isMobile } from 'react-device-detect';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Location } from 'history';
import {
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom';

import {
    Button,
    Link,
    MenuItem,
    Select,
    Snackbar,
    Typography,
} from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';

import categoryAPI from '../../../api/category';
import reelAPI from '../../../api/reel';
import setAPI from '../../../api/set';
import strandAPI from '../../../api/strand';
import teacherSessionAPI from '../../../api/teacherSession';
import themeAPI from '../../../api/theme';

import { CachedReel } from '../../../interfaces/cachedReel';
import { Category } from '../../../interfaces/category';
import {
    Reel,
    ReelSelection,
} from '../../../interfaces/reel';
import { Set } from '../../../interfaces/set';
import { Strand } from '../../../interfaces/strand';
import { StrandSets } from '../../../interfaces/strandSets';
import { Theme } from '../../../interfaces/theme';

import {
    emptyCategory,
    emptyReel,
    emptySet,
    emptyStrand,
    emptyTheme,
} from '../../../interfaces/defaults';

import Layout from '../../Layout';
import StepNavigator, { STEP } from './StepNavigator';
import ReelContentSection from './ReelContentSection';

import './PreviewReel.scss';

const getReelSelection = (strandSets: StrandSets, strandId: string, setId: string): ReelSelection => ({
    setContents: strandSets.blocks.map((strandsByBlock) => {
        const strand = strandsByBlock.strands.find((strand) => strand.id === strandId);
        if (!strand) return '';

        let setContent;
        if (strand.setContents.length === 1) {
            setContent = strand.setContents[0];
        } else {
            setContent = strand.setContents.find((setContent) => setContent.setId === setId);
        }

        return setContent ? setContent.id : '';
    }).filter((setContent) => !!setContent),
    skippedLessons: [],
});

const getReelSelectionFromLocation = (location: Location): ReelSelection => {
    const query = new URLSearchParams(location.search);
    const queryParams = {
        setContents: query.get('setContents'),
        skippedLessons: query.get('skippedLessons'),
    };

    return {
        setContents: queryParams.setContents ? queryParams.setContents.split(',') : [],
        skippedLessons: queryParams.skippedLessons ? queryParams.skippedLessons.split(',') : [],
    };
};

interface Props {
    paraprofessional: boolean;
}

const PreviewReel: React.FC<Props> = ({ paraprofessional = false }) => {
    const [category, setCategory] = useState<Category>({ ...emptyCategory });
    const [theme, setTheme] = useState<Theme>({ ...emptyTheme });
    const [strand, setStrand] = useState<Strand>({ ...emptyStrand });
    const [set, setSet] = useState<Set>({ ...emptySet });
    const [strandSets, setStrandSets] = useState<StrandSets>({ blocks: [] });
    const [reel, setReel] = useState<Reel>({ ...emptyReel });
    const [editing, setEditing] = useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');

    const {
        categoryId,
        reelId,
        strandId,
        setId,
        themeId,
    } = useParams();

    const history = useHistory();
    const location = useLocation();

    const pruneLocalStorage = () => {
        const localStorageArray: CachedReel[] = [];
        Object.keys(localStorage).forEach(function(key) {
            const val = localStorage.getItem(key);
            try {
                if (val) {
                    // @ts-ignore
                    localStorageArray.push({ ...JSON.parse(val), key });
                }
            } catch (e) {
                return;
            }

            return;
        });

        localStorageArray.sort((a, b) => a.creationDate - b.creationDate);
        if (localStorageArray.length >= 7) {
            let counter = 6;
            while (counter <= localStorageArray.length - 1) {
                let key = localStorageArray[counter].key;
                localStorage.removeItem(key);
                counter++;
            }
        }
    };

    const beginSession = async () => {
        pruneLocalStorage();

        const savedReel = !reel.id
          ? await reelAPI.create(themeId, reel)
          : reel;
        if (!savedReel || !savedReel.id) return;

        const session = await teacherSessionAPI.initSession(
          savedReel.theme.id,
          savedReel.id,
          paraprofessional
        );

        const reelContent = await reelAPI.reelContent(savedReel.id);

        localStorage.setItem(session.id, JSON.stringify({
            theme: theme.id ? theme : await themeAPI.single(savedReel.theme.id),
            reel: reelContent,
            creationDate: Date.now(),
        }));

        history.push(`/teacher-session/${session.id}`);
    };

    const getCustomReelParams = () => {
        const selection = reel.reelSelection;
        if (!selection) return '';

        return `setContents=${selection.setContents.join(',')}&skippedLessons=${selection.skippedLessons.join(',')}`;
    };

    const copySessionUrl = async (url: string) => {
        await navigator.clipboard.writeText(url);

        setSnackbarMessage('URL has been copied');
        setOpenSnackbar(true);
    };

    const handleAdvancedMenu = async (event: React.ChangeEvent<{ value: unknown }>) => {
        let url = `${document.location.origin}${reelId ? '/reel/preview' : '/reel/create'}`;

        switch (event.target.value as string) {
            case 'edit':
                setEditing(true);
                return;

            case 'paraprofessional':
                url = `${url}/paraprofessional/`;
                break;

            case 'caregiver':
                url = `${url}/caregiver/`;
                break;
        }

        url = reel.id
          ? `${url}${reel.id}`
          : `${url}${categoryId}/${themeId}/${strandId}/${setId}?${getCustomReelParams()}`;

        await copySessionUrl(url);
    };

    useEffect(() => {
        if (reelId) return;

        (async () => setCategory(await categoryAPI.single(categoryId)))();
        (async () => setTheme(await themeAPI.single(themeId)))();
        (async () => setStrand(await strandAPI.single(strandId)))();
        (async () => setSet(await setAPI.single(setId)))();
    }, [categoryId, reelId, setId, strandId, themeId]);

    useEffect(() => {
        (async () => {
            if (strandSets.blocks.length > 0) return;

            if (reelId) {
                const fetchedReel = await reelAPI.single(reelId);
                const fetchedStrandSets = await themeAPI.getStrandSets(fetchedReel.theme.id);

                setReel(fetchedReel);
                setStrandSets(fetchedStrandSets);

                return;
            }

            // Previewing unsaved reel
            const fetchedStrandSets = await themeAPI.getStrandSets(themeId);
            setStrandSets(fetchedStrandSets);

            if (paraprofessional) {
                setReel({ ...reel, reelSelection: getReelSelectionFromLocation(location) });
            } else {
                setReel({ ...reel, reelSelection: getReelSelection(fetchedStrandSets, strandId, setId) });
            }
        })();
    }, [location, paraprofessional, reel, reelId, setId, strandId, strandSets, themeId]);

    return (
        <Layout title="View Your REEL">
            {(!reelId && !paraprofessional) && (
                <StepNavigator currentStep={STEP.PREVIEW} category={category} theme={theme} strand={strand} set={set} />
            )}

            <div className="section description-section">
                <Typography variant="body2" color="textPrimary">
                    Please find a preview of your dynamically created REEL below.  Each slide detailed below represents
                    the initial slide for that lesson. Lessons may have anywhere from 1 – 10 slides on average.
                    Click below to begin your REEL session!
                    <br />
                    <br />
                    {!paraprofessional && (
                        <span>
                            For further information on how to reorganize the REEL previewed below
                            {' '}
                            <Link
                                href="https://www.starautismsupport.com/video/how-reorganize-reel"
                                target="_blank"
                                rel="noopener"
                            >
                                click here
                            </Link>
                        .
                    </span>
                  )}
              </Typography>
          </div>

          {/*@ts-ignore*/}
          <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              <ReelContentSection
                strandSets={strandSets}
                reelSelection={reel.reelSelection}
                editing={editing}
                onCancel={() => setEditing(false)}
                onUpdateSelection={(reelSelection) => {
                    setReel({ ...reel, reelSelection });
                    if (editing) setEditing(false);
                }}
              />
          </DndProvider>

          <div className="section button-section">
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={editing}
                onClick={beginSession}
              >
                  BEGIN SESSION
              </Button>
              {!paraprofessional && (
                <Select
                  id="select"
                  value="default"
                  disabled={editing}
                  onChange={(e) => handleAdvancedMenu(e)}
                >
                    <MenuItem className="hidden" disabled value="default">Advanced</MenuItem>
                    <MenuItem value="paraprofessional">Create a Paraprofessional Session Link</MenuItem>
                    <MenuItem value="caregiver">Create a Caregiver Session Link</MenuItem>
                    <MenuItem value="edit">Reorganize REEL</MenuItem>
                </Select>
              )}
          </div>

          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
              <MuiAlert elevation={6} variant="filled" severity="info">
                  {snackbarMessage}
              </MuiAlert>
          </Snackbar>
</Layout>
    );
};

export default PreviewReel;
