import React, { ReactNode } from 'react';

import Container from '@material-ui/core/Container';

import Header from './shared/Header';

interface Props {
    className?: string,
    title?: string,
    withHeader?: boolean,
    children: ReactNode,
}

const Layout: React.FC<Props> = ({
    className,
    title,
    withHeader = true,
    children,
}) => (
    <Container className={className ?? ''}>
        {withHeader && <Header title={title} />}

        <div className="main-content">
            {children}
        </div>
    </Container>
);

export default Layout;
