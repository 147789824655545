import React, { useEffect, useState } from 'react';
import ReactJWPlayer from 'react-jw-player';
import { Player } from '../../interfaces/player';
import playerAPI from '../../api/player';

interface Props {
    player: Player,
}

const IntroVideo: React.FC<Props> = ({ player }) => {
    const [videoComplete, setVideoComplete] = useState<boolean>(false);
    const [videoUrl, setVideoUrl] = useState<null|string>(null);

    useEffect(() => {
        const loadVideoUrl = async () => {
            // HhjG0qxt is a hardcoded intro video
            setVideoUrl((await playerAPI.getSignedMedia('HhjG0qxt')).url);
        };

        loadVideoUrl();
    }, [])

    if (videoComplete || videoUrl === null) {
        return null;
    }

    return (
        <ReactJWPlayer
            className="intro-video-player slide"
            playerId={player.id}
            playerScript={`${player.url}`}
            playlist={videoUrl}
            onComplete={() => setVideoComplete(true)}
            customProps={{
                autostart: true,
                controls: false,
                mute: false,
            }}
        />
    );
};

export default IntroVideo;
