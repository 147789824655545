import { StudentAction, TeachingSession } from '../interfaces/teachingSession';

import SolsApiCall from './index';

import { apiUrl, mercureUrl } from './urls';

type StudentMessageHandler = (this: EventSource, ev: MessageEvent) => any;

type TeacherSessionAPI = {
    initSession: (themeId: string, reel: string, paraprofessional: boolean) => Promise<TeachingSession>;
    single: (id: string) => Promise<TeachingSession>;
    updateNavigation: (id: string, navigationData: any) => Promise<TeachingSession>;
    endSession: (id: string) => Promise<void>;
    addStudentToSession: (id: string, studentName: string) => Promise<void>;
    sendStudentAction: (id: string, studentName: string, studentAction: any) => Promise<TeachingSession>;
    subscribeUser: (id: string, onMessage: StudentMessageHandler) => void;
};

const teacherSessionAPI: TeacherSessionAPI = {
    async initSession(themeId, reelId, paraprofessional): Promise<TeachingSession> {
        const response = await SolsApiCall('/teaching_sessions', {
            method: 'POST',
            body: JSON.stringify({
                theme: `/themes/${themeId}`,
                reel: `/reels/${reelId}`,
                paraprofessional,
            }),
        });

        return response.json();
    },

    async single(id): Promise<TeachingSession> {
        const response = await SolsApiCall(`/teaching_sessions/${id}`);

        return response.json();
    },

    async updateNavigation(id, navigationData): Promise<TeachingSession> {
        const response = await SolsApiCall(`/teaching_sessions/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({ navigationData }),
        });

        return response.json();
    },

    async endSession(id): Promise<void> {
        await SolsApiCall(`/teaching_sessions/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({ 'deletedAt': new Date().toISOString() }),
        });
    },

    async addStudentToSession(id, studentName): Promise<void> {
        const students: { [key: string]: Array<null> } = {}
        students[`${studentName}`] = [];

        await SolsApiCall(`/teaching_sessions/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({ 'navigationData': { 'students': students } }),
        })
    },

    async sendStudentAction(id, studentName, studentAction): Promise<TeachingSession> {
        const students: { [key: string]: StudentAction; } = {};
        students[studentName] = {
            ...studentAction,
            date: new Date(),
        };

        const response = await SolsApiCall(`/teaching_sessions/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                navigationData: {
                    students,
                },
            }),
        });

        return response.json();
    },

    subscribeUser(id, onMessage): void {
        const topic = `${apiUrl}/teaching_sessions/${id}`;

        const url = new URL(mercureUrl);
        url.searchParams.append('topic', topic);

        const eventSource = new EventSource(url.toString());
        eventSource.onmessage = onMessage;
    }
};

export default teacherSessionAPI;
