import React, { Dispatch } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {
    ActionType,
    NavigationActionTypes,
    NavigationState,
} from './slideNavigationReducer';

import './BlockNavigationTabs.scss';

interface Props {
    state: NavigationState,
    dispatch: Dispatch<NavigationActionTypes>
}

const BlockNavigationTabs: React.FC<Props> = ({ state, dispatch }) => (
  <Tabs
    className="block-tabs-container"
    value={state.blockIndex}
    onChange={(e, newValue) => dispatch({ type: ActionType.goToBlockIndex, index: newValue })}
    indicatorColor="primary"
    textColor="primary"
    variant="scrollable"
    scrollButtons="auto"
    aria-label="scrollable auto tabs"
  >
      {state.reelContent.blocks.map((block) => <Tab
        key={block.id}
        label={block.name}
        id={`scrollable-auto-tab-${block.id}`}
        aria-controls={`scrollable-auto-tabpanel-${block.id}`}
      />)}
  </Tabs>
);

export default BlockNavigationTabs;
