import {
    emptyBlockContent,
    emptyLessonContent,
    emptySlide,
} from '../../interfaces/defaults';
import {
    BlockContent,
    LessonContent,
    ReelContent,
} from '../../interfaces/reel';
import { SlideType } from '../../interfaces/slideType';

export interface NavigationState {
    block: BlockContent;
    blockIndex: number;
    choiceSlide?: SlideType | null;
    lessonIndex: number;
    lesson: LessonContent;
    reelContent: ReelContent;
    slideIndex: number;
    slide: SlideType;
    isChoiceSlide: boolean;
}

export enum ActionType {
    loadReel,
    goToBlockIndex,
    goToLessonIndex,
    goToSlideIndex,
    nextSlide,
    previousSlide,
    setSlide,
}

type LoadReel = {
    type: ActionType.loadReel,
    reelContent: ReelContent,
};

type GoToBlockIndex = {
    type: ActionType.goToBlockIndex,
    index: number,
};

type GoToLessonIndex = {
    type: ActionType.goToLessonIndex,
    index: number,
};

type GoToSlideIndex = {
    type: ActionType.goToSlideIndex,
    index: number,
};

type NextSlide = {
    type: ActionType.nextSlide,
};

type PreviousSlide = {
    type: ActionType.previousSlide,
};

type SetSlide = {
    type: ActionType.setSlide,
    slide: SlideType,
};

export type NavigationActionTypes =
  | LoadReel
  | GoToBlockIndex
  | GoToLessonIndex
  | GoToSlideIndex
  | NextSlide
  | PreviousSlide
  | SetSlide;

export const initialNavigationState: NavigationState = {
    reelContent: { blocks: [] },
    block: emptyBlockContent,
    blockIndex: 0,
    lesson: emptyLessonContent,
    lessonIndex: 0,
    slide: emptySlide,
    slideIndex: 0,
    isChoiceSlide: false,
};

export type SlideNavigationReducerType = (
  state: Readonly<NavigationState>,
  action: Readonly<NavigationActionTypes>
) => NavigationState;

type Transformer = (state: Readonly<NavigationState>) => NavigationState;

const nextBlockState: Transformer = (state) => {
    if (state.reelContent.blocks.length <= state.blockIndex +1 ) {
        return state; // Noop
    }

    const blockIndex = state.blockIndex + 1;
    const block = state.reelContent.blocks[blockIndex];
    const lessonIndex = 0;
    const lesson = block.lessons[lessonIndex];
    const slideIndex = 0;
    const slide = lesson.slides[slideIndex];

    return {
        ...state,
        blockIndex,
        block,
        lessonIndex,
        lesson,
        slideIndex,
        slide,
    };
}

const previousBlockState: Transformer = (state) => {
    if (state.blockIndex <= 0) {
        return state; // Noop
    }

    const blockIndex = state.blockIndex - 1;
    const block = state.reelContent.blocks[blockIndex];
    const lessonIndex = block.lessons.length - 1;
    const lesson = block.lessons[lessonIndex];
    const slideIndex = lesson.slides.length - 1;
    const slide = lesson.slides[slideIndex];

    return {
        ...state,
        blockIndex,
        block,
        lessonIndex,
        lesson,
        slideIndex,
        slide,
    };
}

const nextLessonState: Transformer = (state) => {
    if (state.block.lessons.length <= state.lessonIndex + 1) {
        return nextBlockState(state);
    }

    const lessonIndex = state.lessonIndex + 1;
    const lesson = state.block.lessons[lessonIndex];
    const slideIndex = 0;
    const slide = lesson.slides[slideIndex];

    return {
        ...state,
        lessonIndex,
        lesson,
        slideIndex,
        slide,
    };
}

const previousLessonState: Transformer = (state) => {
    if (state.lessonIndex <= 0) {
        return previousBlockState(state);
    }

    const lessonIndex = state.lessonIndex-1;
    const lesson = state.block.lessons[lessonIndex];
    const slideIndex = lesson.slides.length - 1;
    const slide = lesson.slides[slideIndex];

    return {
        ...state,
        lessonIndex,
        lesson,
        slideIndex,
        slide,
    };
};

const nextSlideState: Transformer = (state) => {
    if (state.lesson.slides.length <= state.slideIndex + 1) {
        return nextLessonState(state);
    }

    const slideIndex = state.slideIndex + 1;
    const slide = state.lesson.slides[slideIndex];

    return {
        ...state,
        slideIndex,
        slide,
        isChoiceSlide: false,
    };
}

const previousSlideState: Transformer = (state) => {
    if (state.isChoiceSlide) {
        return {
            ...state,
            slide: state.lesson.slides[state.slideIndex],
            isChoiceSlide: false,
        };
    }

    if (state.slideIndex <= 0) {
        return previousLessonState(state);
    }

    return {
        ...state,
        slideIndex: state.slideIndex - 1,
        slide: state.lesson.slides[state.slideIndex - 1],
        isChoiceSlide: false,
    };
}

const slideNavigationReducer: SlideNavigationReducerType = (state, action) => {
    switch (action.type) {
        case ActionType.loadReel: {
            const { reelContent } = action;
            const block = reelContent.blocks[0];
            const lesson = block.lessons[0];
            const slide = lesson.slides[0];

            return { ...state, reelContent, block, lesson, slide };
        }
        case ActionType.goToBlockIndex: {
            const blockIndex = action.index;
            const block = state.reelContent.blocks[blockIndex];
            const lessonIndex = 0;
            const lesson = block.lessons[0];
            const slideIndex = 0;
            const slide = lesson.slides[0];

            return { ...state, blockIndex, block, lessonIndex, lesson, slideIndex, slide };
        }
        case ActionType.goToLessonIndex: {
            const lessonIndex = action.index;
            const lesson = state.block.lessons[lessonIndex];
            const slideIndex = 0;
            const slide = lesson.slides[0];

            return { ...state, lessonIndex, lesson, slideIndex, slide };
        }
        case ActionType.goToSlideIndex: {
            const slideIndex = action.index;
            const slide = state.lesson.slides[slideIndex];

            return { ...state, slideIndex, slide };
        }
        case ActionType.nextSlide:
            return nextSlideState(state);
        case ActionType.previousSlide:
            return previousSlideState(state);
        case ActionType.setSlide:
            return {
                ...state,
                slide: action.slide,
                isChoiceSlide: true,
            };
        default:
            throw new Error();
    }
};

export default slideNavigationReducer;
