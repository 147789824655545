import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Category } from '../../../interfaces/category';
import { Set } from '../../../interfaces/set';
import { Strand } from '../../../interfaces/strand';
import { Theme } from '../../../interfaces/theme';

import './StepNavigator.scss';

enum STEP {
    CATEGORY,
    THEME,
    STRAND,
    SET,
    PREVIEW,
}

interface Props {
    currentStep: STEP,
    category?: Category,
    theme?: Theme,
    strand?: Strand,
    set?: Set,
}

const navigationItemClassName = (step: STEP, currentStep: STEP, selection: any) => (
    `step-navigation-item ${step === currentStep ? 'active' : ''} ${selection ? 'selection' : ''}`
);

const StepNavigator: React.FC<Props> = ({
    currentStep,
    category,
    theme,
    strand,
    set,
}) => (
    <div className="step-navigator">
        <div className={navigationItemClassName(STEP.CATEGORY, currentStep, category)}>
            <Typography variant="h3">{`CATEGORY${category ? ':' : ''}`}</Typography>
            {category && <Typography variant="h6">{category.name}</Typography>}
        </div>

        <div className={navigationItemClassName(STEP.THEME, currentStep, theme)}>
            <Typography variant="h3">{`THEME${theme ? ':' : ''}`}</Typography>
            {theme && <Typography variant="h6">{theme.name}</Typography>}
        </div>

        <div className={navigationItemClassName(STEP.STRAND, currentStep, strand)}>
            <Typography variant="h3">{`STRAND${strand ? ':' : ''}`}</Typography>
            {strand && <Typography variant="h6">{strand.name}</Typography>}
        </div>

        <div className={navigationItemClassName(STEP.SET, currentStep, set)}>
            <Typography variant="h3">{`SET${set ? ':' : ''}`}</Typography>
            {set && <Typography variant="h6">{set.name}</Typography>}
        </div>

        <div className={navigationItemClassName(STEP.PREVIEW, currentStep, null)}>
            <Typography variant="h3">PREVIEW</Typography>
        </div>
    </div>
);

export default StepNavigator;
export { STEP };
