import React from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { Theme } from '../../../interfaces/theme';
import { Category } from '../../../interfaces/category';

import './ReelItemCard.scss';

interface Props {
    item: Theme | Category,
    active: boolean,
    onClick?: () => void,
}

const ReelItemCard: React.FC<Props> = ({ item, active, onClick }) => (
    <Card onClick={onClick} className={`reel-item-card ${active ? 'active' : ''}`}>
        <CardActionArea>
            <CardMedia image={item.image}>
                {active && <div className="reel-item-checkmark" />}
            </CardMedia>
            <CardContent>
                <Typography gutterBottom variant="h5" color="textSecondary">{item.name}</Typography>
                {('subtitle' in item) && (
                    <Typography variant="body1" color="textSecondary">{item.subtitle}</Typography>
                )}
            </CardContent>
        </CardActionArea>
    </Card>
);

export default ReelItemCard;
