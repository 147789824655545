import React, { useRef, useState } from 'react';

import {
    DropTargetMonitor,
    useDrag,
    useDrop,
} from 'react-dnd';
import { XYCoord } from 'dnd-core';

import {
    FormControl,
    InputLabel,
    ListSubheader,
    MenuItem,
    Paper,
    Select,
    Typography,
    makeStyles,
} from '@material-ui/core';
import {
    CheckCircle as CheckCircleIcon,
    DragHandle as DragHandleIcon,
    NotInterested as NotInterestedIcon,
} from '@material-ui/icons';

import { DraggableItemTypes } from './DraggableItemTypes';

import { BlockContent, LessonContent } from '../../../interfaces/reel';
import { StrandSets } from '../../../interfaces/strandSets';

import './BlockPreview.scss';

interface Props {
    editing: boolean;
    block: BlockContent;
    strandSets: StrandSets;
    onToggleLessonActive: (lesson: LessonContent) => void;
    onChangeSetContent: (setContentId: string) => void;
    findBlock: (id: string) => { index: number };
    moveBlock: (id: string, to: number) => void;
}

interface DragItem {
    type: string;
    id: string;
    originalIndex: number;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    label: {
        color: theme.palette.text.primary,
    },
    listSubheader: {
        color: theme.palette.text.primary,
        lineHeight: 2,
        fontSize: '1rem',
    },
    menuItem: {
        fontSize: '0.85rem',
        padding: '6px 26px',
    },
}));

const BlockPreview: React.FC<Props> = ({
   editing,
   block,
   strandSets,
   onToggleLessonActive,
   onChangeSetContent,
   findBlock,
   moveBlock,
}) => {
    const [setContentId, setSetContentId] = useState<string>(block.setContentId);

    const classes = useStyles();

    const container = useRef<HTMLDivElement | null>(null);

    const originalIndex = findBlock(block.id).index;

    const handleSelectSet = async (event: any) => {
        const value = event.target.value;
        onChangeSetContent(value);
        setSetContentId(value);
    };

    const renderStrandSets = () => {
        const strandsByBlock = strandSets.blocks.find((strandsByBlock) => strandsByBlock.id === block.id);
        if (!strandsByBlock) return null;

        const strands = strandsByBlock.strands.sort((a, b) => a.name.localeCompare(b.name));
        return strands.map((setContentsByStrand) => [
            <ListSubheader className={classes.listSubheader}>
                {setContentsByStrand.name}
            </ListSubheader>,
            ...setContentsByStrand.setContents.map((setContent) => (
              <MenuItem value={setContent.id} className={classes.menuItem}>
                  {setContentsByStrand.name} - {setContent.setName}
              </MenuItem>
            ))
        ]);
    };

    const [{ isDragging }, drag, dragPreview] = useDrag({
        item: { type: DraggableItemTypes.BLOCK, id: block.id, originalIndex },
        canDrag: editing,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: DraggableItemTypes.BLOCK,
        canDrop: () => false,
        hover (item : DragItem, monitor: DropTargetMonitor) {
            if (!container.current) return;

            const draggedId = item.id;
            if (draggedId === block.id) return;

            const { index: overIndex } = findBlock(block.id);

            const hoverBoundingRect = container.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (item.originalIndex < overIndex && hoverClientY < hoverMiddleY) return;
            if (item.originalIndex > overIndex && hoverClientY > hoverMiddleY) return;

            moveBlock(draggedId, overIndex);
        },
    });

    return (
      <div
        ref={(node) => {
            dragPreview(drop(node));
            container.current = node;
        }}
        className={`block-preview${editing ? ' editing' : ''}`}
        style={{ opacity: isDragging ? 0.1 : 0.99 }}
      >
          {editing && (
            <div ref={drag} className="drag-handle">
                <DragHandleIcon />
            </div>
          )}

          <div className="preview-name">
              <Typography variant="h6" color="textPrimary">
                  {block.name}
              </Typography>
              {editing && (
                <FormControl className={classes.formControl}>
                    <InputLabel id={`strands-sets-select-label${block.id}`} className={classes.label}>
                        Select Strand
                    </InputLabel>
                    <Select
                      labelId={`strands-sets-select-label${block.id}`}
                      className="strands-sets-dropdown"
                      value={setContentId}
                      onChange={handleSelectSet}
                    >
                        <MenuItem value="hide">
                            <em>Hide this Block</em>
                        </MenuItem>

                        {renderStrandSets()}
                    </Select>
                </FormControl>
              )}
          </div>

          <div className="preview-lessons">
              {block.lessons.filter((lesson) => !lesson.activity).map((lesson) => (
                <Paper
                  key={lesson.id}
                  className={`lesson-preview ${!lesson.active ? 'lesson-inactive' : ''}`}
                  onClick={() => {
                      if (!editing) return;
                      onToggleLessonActive(lesson);
                  }}
                >
                    <img src={lesson.slides[0].image} alt={`Lesson ${lesson.name}`} />
                    {editing && (
                      <div className="lesson-active-indicator" >
                          {lesson.active && <CheckCircleIcon />}
                          {!lesson.active && <NotInterestedIcon />}
                      </div>
                    )}
                </Paper>
              ))}
          </div>
      </div>
    );
};

export default BlockPreview;
