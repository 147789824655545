import { apiUrl } from './urls';

type APIOptionsType = {
    method?: string;
    body?: any;
};

type SolsAPI = (path: string, options?: APIOptionsType) => Promise<Response>;

const SolsApiCall: SolsAPI = async (path, options = { method: 'GET' }) => {
    let mimeType = '';
    if (options.body) {
        mimeType = options.method === 'POST' ? 'application/json' : 'application/merge-patch+json';
    }

    const fetchOptions = {
        method: options.method,
        body: options.body,
        headers: {
            'Content-Type': mimeType,
            Accept: 'application/json',
        },
    };

    const url = `${apiUrl}${path}`;
    const response = await fetch(url, fetchOptions);

    if (response.ok) {
        return response;
    }

    throw await response.json();
};

export default SolsApiCall;
