import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
} from 'react';

import ReactJWPlayer from 'react-jw-player';

import { Media, Player } from '../../interfaces/player';

declare global {
    interface Window {
        jwplayer: (id: string) => any;
    }
}

interface Props {
    videoData: Media
    player: Player,
    onPlay?: () => any,
    onReady?: () => void,
    onClick?: (evt: any) => void,
}

const noOp = () => {};

const StudentVideoPlayer = forwardRef((props: Props, ref?) => {
    const {
        player,
        videoData,
        onPlay = noOp,
        onReady = noOp,
        onClick = noOp,
    } = props;

    const playingRef = useRef<boolean>(false);

    /* Export methods of the player */
    useImperativeHandle(ref, () => ({
        play() {
            const jwPlayerInstance = window.jwplayer(player.id);
            const videoElem = jwPlayerInstance.getContainer().querySelector('video');
            if (videoElem) {
                videoElem.play();
            }
        },
        pause() {
            const jwPlayerInstance = window.jwplayer(player.id);
            const videoElem = jwPlayerInstance.getContainer().querySelector('video');
            if (videoElem) {
                videoElem.pause();
            }
        },
        setVolume(volume: number) {
            const jwPlayerInstance = window.jwplayer(player.id);
            jwPlayerInstance.setVolume(volume);
        },
        seek(position: number) {
            const jwPlayerInstance = window.jwplayer(player.id);
            jwPlayerInstance.seek(position);
        },
        isPlaying() {
            return playingRef.current;
        },
        setMute(state: boolean) {
            const jwPlayerInstance = window.jwplayer(player.id);
            jwPlayerInstance.setMute(state);
        },
    }));

    return (
        <ReactJWPlayer
            className="slide student-player"
            playerId={player.id}
            playerScript={`${player.url}`}
            playlist={videoData.url}
            onReady={() => {
                const jwPlayerInstance = window.jwplayer(player.id);

                const container = jwPlayerInstance.getContainer();
                container.addEventListener('click', onClick);

                onReady();
            }}
            onPlay={() => {
                if (onPlay()) {
                    playingRef.current = true;
                }
            }}
            onResume={() => {
                playingRef.current = true;
            }}
            onPause={() => {
                playingRef.current = false;
            }}
            customProps={{
                autostart: false,
                controls: false,
                mute: false,
                volume: 50,
            }}
        />
    );
});

export default StudentVideoPlayer;