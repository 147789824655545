import { Strand } from '../interfaces/strand';

import SolsApiCall from './index';

type StrandAPI = {
    listing: (themeId: string) => Promise<Strand[]>;
    single: (id: string) => Promise<Strand>;
};

const strandAPI: StrandAPI = {
    async listing(themeId): Promise<Strand[]> {
        const response = await SolsApiCall(`/strands?theme=${themeId}`);

        return response.json();
    },

    async single(id): Promise<Strand> {
        const response = await SolsApiCall(`/strands/${id}`);

        return response.json();
    },
}

export default strandAPI;
